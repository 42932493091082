import React from "react"
import SolutionsLayout from "../../components/SolutionsLayout"
import { Grid } from "@material-ui/core"
import Card from "../../components/Card"

export default function Architecture() {
  return (
    <SolutionsLayout>
      <h2 className="heading-2 left">Architecture</h2>
      <p className="paragraph left">
        <strong className="bold-text">
          Operational Excellence, Security, Reliability, Performance Efficiency,
          Cost Optimization: The 5 pillars of a well architected workload.
          <br />
          <br />
          Adhering to architectural best practices and strategies greatly
          increases the likelihood of business success.
        </strong>
        <br />
        <br />
        <b>Operational Excellence:</b> View your entire workload (applications,
        infrastructure, policy, governance, and operations) as code. This means
        you can apply the same engineering discipline that you use for
        application code to every element of your stack and share these across
        teams or organizations to magnify the benefits of development efforts.
        Use operations as code in the cloud and the ability to safely experiment
        to develop your workload, your operations procedures, and practice
        failure.
        <br />
        <br />
        <b>Security:</b> Apply overarching best practices to every area of
        security. Take requirements and processes that you have defined in
        operational excellence at an organizational and workload level, and
        apply them to all areas.
        <br />
        <br />
        <b>Reliability:</b> When you architect a workload to automatically add
        and remove resources in response to changes in demand, this not only
        increases reliability but also ensures that business success doesn't
        become a burden. With monitoring in place, your team will be
        automatically alerted when KPIs deviate from expected norms. Automatic
        logging of changes to your environment allows you to audit and quickly
        identify actions that might have impacted reliability. Controls on
        change management ensure that you can enforce the rules that deliver the
        reliability you need.
        <br />
        <br />
        <b>Performance Efficiency:</b> You can go global in minutes and deploy
        resources in multiple locations across the globe to be closer to your
        end users. You can also dynamically add read-only replicas to
        information stores to reduce the load on the primary database.
        <br />
        <br />
        <b>Cost Optimization:</b> A well-architected workload uses the most
        cost-effective resources, which can have a significant and positive
        economic impact. You also have the opportunity to use managed services
        to reduce costs.
        <br />
        <br />
      </p>
      <h2 className="heading-2 left">Benefits</h2>
      <ul className="list">
        <li>
          <b>Stop guessing your capacity needs:</b> Use as much or as little as
          you need, automatically.
        </li>
        <li>
          <b>Test systems at scale:</b> The cloud provides you with nearly
          bottomless capacity for the duration of your testing.
        </li>
        <li>
          <b>Automate to make experimentation easier:</b> Track changes to your
          automation, audit and revert if necessary
        </li>
        <li>
          <b>Evolutionary architectures:</b> Provide business with the solution
          to their needs with short turnaround time.
        </li>
      </ul>
      <h2 className="heading-2 left">What we offer</h2>
      <Grid container alignItems="stretch" className={"w-container"}>
        <Grid item xs={12} lg={6}>
          <Card title="Operational Excellence" icon={3}>
            Infrastructure and operations as code. Auditable changes.
            Data-driven approach.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Security" icon={12}>
            Multi-layered approach. Automated gap assessments. Encryption
            everywhere. Automated log and metric collections.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Reliability" icon={16}>
            Built for high resiliency. Auto-scaling, self-healing deployments.
            <br />
            Consume the right amount of resources to meet needs. Automated
            pipelines.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Performance Efficiency" icon={7}>
            Advanced technologies selected to fit your project.
            <br />
            Global presence to better serve customers.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Cost Optimization" icon={13}>
            Refined cost tracking. Consumption model with long term visibility
            for each project. Workload cost efficiency assessment.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Assessments" icon={5}>
            Automated AWS Well-Architected framework assessments.
            <br />
            CIS Benchmarks compliance.
          </Card>
        </Grid>
      </Grid>

      <br />
      <h2 className="heading-2 left">Why partner with us</h2>
      <ul className="list">
        <li>Rely on years of experience building resilient systems.</li>
        <li>Automation everywhere: we build it, you own it.</li>
        <li>Accurate cloud cost planning for each project</li>
        <li>Data-driven approach</li>
      </ul>
    </SolutionsLayout>
  )
}
